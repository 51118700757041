export default {
  head: {
    title: "Website is temporarily unavailable",
    meta: [
      { name: "description", content: "MedForm - Website is temporarily unavailable" }
    ]
  },
  error: "Website is temporarily unavailable",
  wait: "Waiting for API availability...",
  refresh: "Refresh page"
}
