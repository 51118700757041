import type { Result } from "@/models/interfaces";
import type ServerTimeModel from "@/models/api/queries/ServerTimeModel";
import { useApolloClient } from "@vue/apollo-composable";
import gql from "graphql-tag";

export default class TimeService {
  public time: ServerTimeModel = {
    clientTime: new Date().toISOString(),
    serverTime: new Date().toISOString(),
    difference: 0
  };

  async getServerTimeAsync(): Promise<Result<ServerTimeModel>> {
    const { client } = useApolloClient();

    try {
      const { data } = await client.query<{ time: ServerTimeModel }>({
        query: gql`
          query time($clientTime: DateTimeType!) {
            time(clientTime: $clientTime) {
              clientTime,
              serverTime,
              difference
            }
          }
        `,
        variables: {
          clientTime: new Date().toISOString()
        }
      });

      this.time = data.time;
      return { success: true, data: data.time };
    } catch (error) {
      // eslint-disable-next-line no-console
      // console.warn("Failed to get server time.", error);
      return { success: false, data: this.time, exception: error?.toString(), message: error?.message };
    }
  }

  async getDateAsync() {
    const result = await this.getServerTimeAsync();
    const time = result.data ?? this.time;
    return new Date(Date.now() + time.difference);
  }

  getDateString(date: Date) {
    return `${date.toISOString().slice(0, -1)}0000Z`;
  }

  async getDateStringAsync() {
    return this.getDateString(await this.getDateAsync());
  }
}
