import GraphqlService from "../GraphqlService";
import { AutoMapper } from "@/utils";
import { treatmentModelAllFields, overviewReportModelAllFields, collectionReportModelAllFields } from "@/models/api/queries/FinancialReportModels";
import { doctorPaymentSchemaAllFields, type DoctorPaymentSchema } from "@/models/schemas";
import type { DateTimeString, TabulatorParams } from "@/models/interfaces";
import type {
  VatReportInput, AdvanceTaxReportInput, OverviewReportInput, AddPaymentInput,
  TreatmentModel, VatReportModel, AdvanceTaxReportModel, OverviewReportModel,
  CollectionReportModel, PaymentToDoctorModel,
  FilterAccountingReportInput
} from "@/models/api/queries/FinancialReportModels";

export default class ReportService {
  //#region overview report
  async getOverviewReportModel(input: OverviewReportInput) {
    const { data } = await GraphqlService.queryGql<OverviewReportModel>({
      method: "overviewReportModel",
      fields: overviewReportModelAllFields,
      variables: [{ field: "input", value: input, valueType: "OverviewReportInput!" }]
    });
    return data;
  }
  //#endregion

  //#region collection report / payment report
  async getCollectionReportModel() {
    const { data } = await GraphqlService.queryGql<CollectionReportModel>({
      method: "collectionReportModel",
      fields: collectionReportModelAllFields,
    });
    return data ? AutoMapper.merge<CollectionReportModel>({}, data) : null;
  }
  /*
  async getCollectionReportModelWithImported() {
    const { data } = await GraphqlService.queryGql<CollectionReportModel>({
      method: "collectionReportModelWithImported",
      fields: collectionReportModelAllFields,
    });
    return data ? AutoMapper.merge<CollectionReportModel>({}, data) : null;
  }
  */
  //#endregion

  //#region vat report
  async getVatReportModel(input: VatReportInput) {
    const modelFields = ["amountLiableForVat", "amountExemptionVat", "amountVat", "expensesAmount", "expensesAmountVat", "totalVatPaid"];
    const { data } = await GraphqlService.queryGql<VatReportModel>({
      method: "vatReportModel", fields: modelFields, variables: [{
        field: "input", value: input, valueType: "VatReportInput"
      }]
    });
    return data;
  }
  //#endregion

  //#region advance tax report
  async getAdvanceTaxReportModel(input: AdvanceTaxReportInput) {
    const modelFields = ["businessTurnover", "advanceTaxPercent", "advanceTaxResult", "totalWithholdingTax", "totalAdvancePaid"];
    const { data } = await GraphqlService.queryGql<AdvanceTaxReportModel>({
      method: "advanceTaxReportModel", fields: modelFields, variables: [{
        field: "input", value: input, valueType: "AdvanceTaxReportInput"
      }]
    });
    return data;
  }
  //#endregion

  //#region accounting report
  async addDoctorPayment(input: AddPaymentInput) {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "addDoctorPayment",
      variables: [{ field: "input", value: input, valueType: "AddPaymentInput" }]
    });
    return data ?? false;
  }

  async deleteDoctorPayment(id: number) {
    return await GraphqlService.deleteItem("deleteDoctorPayment", id);
  }

  async getDoctorPayments(params: TabulatorParams, id: number) {
    return await GraphqlService.getItems<DoctorPaymentSchema>("doctorPayments", doctorPaymentSchemaAllFields, params, {
      variables: [{ field: "id", value: id, valueType: "ID!" }]
    });
  }

  async getDoctorTreatments(params: TabulatorParams, id: number) {
    return await GraphqlService.getItems<TreatmentModel>("doctorTreatments", treatmentModelAllFields, params, {
      variables: [{ field: "id", value: id, valueType: "ID!" }]
    });
  }

  async getPaymentToDoctors(params: TabulatorParams, filterInput: FilterAccountingReportInput) {
    const paymentToDoctorModelFields = ["doctorId", "doctorName", "doctorImage", "amountOwed"];
    return await GraphqlService.getItems<PaymentToDoctorModel>("paymentToDoctors", paymentToDoctorModelFields, params, {
      variables: [{ field: "filterInput", value: filterInput, valueType: "FilterAccountingReportInput" }]
    });
  }

  async getOpenTreatments(params: TabulatorParams, filterInput: FilterAccountingReportInput) {
    return await GraphqlService.getItems<TreatmentModel>("openTreatments", treatmentModelAllFields, params, {
      variables: [{ field: "filterInput", value: filterInput, valueType: "FilterAccountingReportInput" }]
    });
  }

  async getCalculatedDoctorTreatments(params: TabulatorParams, doctorId: number) {
    return await GraphqlService.getItems<TreatmentModel>("calculatedDoctorTreatments", treatmentModelAllFields, params, {
      variables: [{ field: "id", value: doctorId, valueType: "ID!" }]
    });
  }
  //#endregion
}
