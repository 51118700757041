export default {
  head: {
    title: "Attendance",
    meta: [
      { name: "description", content: "Attendance description" }
    ]
  },
  title: "Attendance",
  automaticTime: "Automatic time",
  addAttendance: "Add Attendance",
  addAttendanceFormHeader: "Add Hours report",
  updateAttendance: "Update work time",
  manualTime: "Manual time",
  member: "Member",
  fromHour: "Clocked in",
  toHour: "Clocked out",
  fromDate: "Date from",
  toDate: "Date to",
  totalDuration: "Total duration",
  note: "Note",
  date: "Date",
  deletePopupText: "Are you sure you want to delete this attendance?",
  attendanceReport: "Attendance Report",
  startAutoLogHours: "Start attendance clock",
  stopAutoLogHours: "Stop attendance clock {0}",
  reportDownload: "Download",
  totalWorked: "Total worked",
  staffPlaceholder: "Select members",
  reportPopup: {
    title: "Select users for report",
    users: "Users",
    allUsers: "All users",
    btns: {
      print: "Print",
      pdf: "Pdf",
      excel: "Excel"
    }
  }
}
