import type { DateTimeString } from "@/models/interfaces";
import type { ShowPatientType, ShowCalendarsType, ShowFinancialType, ShowType } from "@/models/enums";

export interface UserPermissionsSchema {
  userId: number;
  createdAt: DateTimeString;
  updatedAt: DateTimeString;

  showPatient: ShowPatientType;
  showCalendars: ShowCalendarsType;
  showCalendarIds?: number[];

  financial: ShowFinancialType;
  financialReports: boolean;
  incomes: boolean;
  expenses: boolean;
  forecast: boolean;
  deposit: boolean;
  accountingReport: boolean;

  appointments: boolean;
  leads: boolean;
  laboratoryWorks: ShowType;
  tasks: ShowType;
  attendance: ShowType;
  settings: boolean;

  emails: boolean;
  recall: ShowType;
  chat: boolean;
  users: boolean;
  patients: boolean;
}

export const userPermissionsSchemaAllFields = `
  userId
  createdAt
  updatedAt

  showPatient
  showCalendars
  showCalendarIds

  financial
  financialReports
  incomes
  expenses
  forecast
  deposit
  accountingReport

  appointments
  leads
  laboratoryWorks
  tasks
  attendance
  settings

  emails
  recall
  chat
  users
  patients
`;
