export default {
  head: {
    title: "מטופל",
    meta: [{ name: "description", content: "מטופל" }]
  },
  backToList: "חזרה למטופלים",
  edit: "עריכה",
  patientNumber: "מספר מטופל",
  patientNameLabel: "שם מטופל:",
  patientBalance: "יתרה לתשלום",
  doctor_warn: "לרופא המשוייך אין יומן",
  selectSection: "בחר סעיף",
  view: {
    debt: "חוב",
    balance: "יתרה",
    phone2: "טלפון נוסף",
    email1: "דוא״ל",
    doctor: "רופא מטפל",
    city: "עיר מגורים",
    id: "מספר מטופל",
    passportNumber: "מספר זהות",
    fileNumber: "מספר תיק",
    priceList: "מחירון",
    attendingDoctor: "רופא מטפל",
    photos: {
      title: "צילומים",
      options: ["לפני", "אחרי", "כללי"]
    },
    xRay: {
      title: "צילום רנטגן",
      options: ["לפני", "אחרי", "כללי"]
    },
    cross: {
      title: "צלב",
      options: ["לפני", "אחרי", "כללי"]
    },
    appointments: {
      titleNext: "3 תורים הקרובים",
      titleLast: "3 ביקורים אחרונים",
      table: {
        columns: {
          id: "ID",
          startTime: "תאריך",
          serviceName: "שירות",
          note: "הערות"
        }
      },
      approve: "לאשר קביעת פגישה",
      cancel: "בטל קביעת פגישה",
    },
    tasks: {
      title: "משימות הקרובות",
      table: {
        columns: {
          id: "ID",
          followUp: "תאריך המשימה",
          title: "הערות"
        }
      },
      close: "סמן את המשימה כבוצעה"
    },
    forms: {
      title: "טפסים",
      send: "שליחת טופס",
      message: "טופס רפואי לא מולא ב-12 החודשים האחרונים"
    },
    files: {
      title: "העלה קבצים",
      drug: "תיקי סמים כאן"
    }
  },
  form: {
    title: "שליחת טופס הרשמה למטופל חדש",
    linkSent: "הקישור נשלח בהצלחה",
    linkToRegister: "לינק להרשמה ל",
    phoneRequired: "מספר טלפון הינו חובה."
  },
  contact: {
    title: "צור קשר עם המטופל",
    template: "תבנית"
  },
  general: {
    createdAt: "נוצרה ב",
    doctor: "רופא",
    priceList: "מחירון",
    readonly: ["נתונים מוצגים לצפיה. לעריכה", "לחץ כאן"],
    title: "פרטי מטופל",
    personalInformation: "פרטים אישיים",
    firstName: "שם פרטי",
    lastName: "שם משפחה",
    surName: "שם האב",
    genderSelect: "לא נבחר",
    gender: "מין",
    genderOptions: ["זכר", "נקבה"],
    passportNumber: "מספר ת\"ז",
    familyStatus: "מצב משפחתי",
    birthDate: "תאריך לידה",
    familyOptions: ["בחר אפשרות", "רווק", "נשוי"],
    active: "סטטוס תיק מטופל",
    activeOptions: ["פעיל", "ארכיון"],
    address: "כתובת",
    street: "רחוב",
    houseNumber: "מספר בית",
    apartment: "דירה",
    city: "עיר",
    postalCode: "מיקוד",
    job: "מקום עבודה",
    contacts: "פרטי יצירת קשר",
    phone1: "1 טלפון",
    phone2: "2 טלפון",
    phone3: "3 טלפון",
    email1: "אימייל 1",
    email2: "אימייל 2",
    fax: "פקס",
    insurrance: "פרטי ביטוח",
    insurranceCompany: "חברת ביטוח",
    policyNumber: "מספר פוליסה",
    endDate: "תאריך סיום",
    conditions: "תנאים",
    employerNumber: "מספר מעסיק",
    companyCode: "קוד חברת ביטוח",
    remarks: "הערות",
    general: "כללי",
    color: "צבע",
    fileNumber: "מספר תיק",
    referredBy: "הופנה ע\"י",
    healthFund: "קופת חולים",
    create: "הוספה",
    balance: {
      title: "יתרת לקוח",
      alert: "אם ללקוח יש יתרת חוב או זכות קודמת, אפשר לעדכן אותה כאן למעקב. היתרה שתוגדר תשפיע על סכום התקבולים של הלקוח, אבל לא על דוח ההכנסות.",
      initial: "עדכון יתרת לקוח",
    },
    validation: {
      exists: "מטופל קיים בבסיס הנתונים."
    },
    popup: {
      title: "התראת כפילות מטופלים",
      legend: "מטופל עם פרטים זהים כבר קיים במערכת",
      patient: "מטופל:",
      patientId: "#:",
      submit: "הצגת מטופלים קיימים",
      saveAnyway: "שמור בכל זאת"
    }
  },
  appointments: {
    title: "תורים",
    selectColumns: "בחירת עמודות",
    newAppointment: "תור חדש",
    canceledByPatient: 'בוטל ע"י המטופל',
    columns: {
      startTime: "תאריך התחלה",
      duration: "משך זמן",
      doctorName: "רופא מטפל",
      serviceName: "שירות",
      note: "הערות",
      status: "סטטוס",
      cancellationReason: "סיבת הביטול",
      cancelledBy: 'בוטל ע"י'
    }
  },
  treatmentPlans: {
    title: "תוכניות טיפול"
  },
  prescriptions: {
    title: "מרשמים"
  },
  documents: {
    title: "מכתבים והפניות"
  },
  medicalForms: {
    title: "טפסים"
  },
  notes: {
    title: "הערות"
  },
  treatments: {
    title: "טיפולים"
  },
  files: {
    title: "קבצים"
  },
  tasks: {
    title: "משימות"
  },
  laboratoryWorks: {
    title: "עבודות מעבדה"
  },
  family: {
    title: "משפחה"
  },
  payments: {
    title: "חשבון"
  },
  tip: {
    customerCard: "כרטיס לקוח",
    treatmentPlan: "תוכנית טיפול",
    careCard: "כרטיס טיפול",
    calendar: "תור חדש",
    addForm: "טופס הסכמה חדש",
    recall: "ריקול",
    newPrescription: "מרשם חדש",
    invoice: "חשבון",
    addPayment: "הוספת מסמך",
    laboratoryWork: "עבודות מעבדה",
    sendMessage: "שליחת הודעה",
    sendEmail: 'שליחת דוא"ל',
    sendAppointment: "שליחת קישור לזימון תור"
  }
};
