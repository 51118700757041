export default {
  title: "הערות",
  table: {
    columns: {
      id: "ID",
      createdAt: "תאריך",
      doctorName: "רופא",
      sourceName: "רופא",
      description: "תיאור"
    }
  },
  popup: {
    titles: {
      edit: "עריכת ממצאים",
      create: "הוספת הערה"
    },
    doctor: "רופא",
    description: "תיאור",
    createdAt: "נוצר",
    noteOnAppointmentCreation: "הערה מודגשת בקביעת פגישה",
    noteOnIncomeCreation: "הערה מודגשת בהפקת מסמך חשבונאי",
    noteOnPatientOpen: "הערה מודגשת בפתיחת כרטיס מטופל",
    submit: "שמירה"
  }
}
