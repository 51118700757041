export default {
  head: {
    title: "דיווח שעות",
    meta: [
      { name: "description", content: "דיווח שעות" }
    ]
  },
  title: "דיווח שעות",
  automaticTime: "זמן אוטומטי",
  addAttendance: "הוספה",
  addAttendanceFormHeader: "הוסף דיווח שעות",
  updateAttendance: "עדכון זמן עבודה",
  manualTime: "זמן ידני",
  member: "איש צוות",
  fromHour: "כניסה",
  toHour: "יציאה",
  fromDate: "מתאריך",
  toDate: "עד תאריך",
  totalDuration: 'סה"כ',
  note: "הערה",
  date: "תאריך",
  deletePopupText: "האם אתה בטוח שברצונך למחוק נוכחות זו",
  attendanceReport: "דוח נוכחות",
  startAutoLogHours: "התחלת שעון נוכחות",
  stopAutoLogHours: "עצירת שעון נוכחות {0}",
  reportDownload: "הורדת הדוח",
  totalWorked: "סך הכל עבד",
  staffPlaceholder: "אנשי צוות",
  reportPopup: {
    title: "בחירת אנשי צוות לייצוא",
    users: "אנשי צוות",
    allUsers: "כל אנשי הצוות",
    btns: {
      print: "הדפסה",
      pdf: "קובץ PDF",
      excel: "קובץ Excel"
    }
  }
}
