export default {
  head: {
    title: "האתר אינו זמין כרגע",
    meta: [
      { name: "description", content: "MedForm - האתר אינו זמין כרגע" }
    ]
  },
  error: "האתר אינו זמין כרגע",
  wait: "ממתין לזמינות ה-API...",
  refresh: "רענן את הדף"
}
