export default {
  title: "Payments",
  titleFamily: "Payments that {0} pays for",
  buttons: {
    add: "Document production",
    addCopy: "Add copy",
    addRelated: "Add related",
    addCardPayment: "Add card payment",
    send: "Send",
    view: "View",
    reopen: "Reopen the document",
    download: "Download"
  },
  form: {
    title: "Add document",
    documentType: "Document type",
    connect: "Connect to treatment or treatment plan",
    submit: "Create",
    typeError: "Not supported income type",
    receiptError: "Receipt type not support invoice lines",
    total: "Total",
    treatmentPlans: {
      title: "Treatment plans",
      table: {
        columns: {
          createdAt: "Create date",
          title: "Title",
          description: "Description",
          status: "Status",
          total: "Total",
          due: "Due"
        }
      }
    },
    treatments: {
      title: "Treatments",
      table: {
        columns: {
          createdAt: "Create date",
          description: "Treatment",
          statusTitle: "Status",
          due: "Due"
        }
      }
    }
  },
  formCard: {
    treatmentPlansTitle: "Advance payment for a treatment plan",
    treatmentsTitle: "Payment for completed treatments",
    title: "Add card payment",
    subTotal: "Subtotal",
    vat: "Vat",
    rounding: "Rounding",
    total: "Total",
    submit: "Proceed to payment",
    payment: {
      amount: "Amount to be paid",
      paymentMaxNumber: "Number of payments",
      vatType: "VAT Type",
      vat: "VAT",
      reason: "Payment reason",
      method: "Payment via",
      terminal: "Terminal",
      form: "Manual form",
      link: {
        btn: "Send link",
        select: "How to send a link for payment",
        options: {
          email: "Email",
          whatsapp: "Whatsapp",
          sms: "SMS",
        },
        input: "To whom to send a payment link",
      },
      submit: "Proceed to payment",
      spinner: {
        terminal: "Please follow the instructions on the EMV terminal.",
      }
    }
  },
  reportPopup: {
    title: "Account summary report",
    includeFinancialDocuments: "Include financial documents",
    includeTreatmentSummary: "Include treatment summary",
    includeDescriptionColumn: "Include description column",
    sendCopy: "Send copy to patient",
    recipientPhone: "Recipient phone number",
    email: "Recipient email",
    emailValidation: 'Email is required',
    print: 'Print',
    printAndSend: 'Print and send',
    emailNotConnected: "Email not connected",
    downloadAndSend: "Download and send",
    download: "Download",
    close: "Close",
    fromDate: "From date",
    toDate: "To date",
    doctorsLabel: "Treating doctor",
    doctorsPlaceholder: "All doctors",
  },
  table: {
    imported: "Imported",
    columns: {
      id: "(m)#", // model
      incomeId: "(i)#", // income
      createdAt: "Date",
      patientName: "Account",
      financialNumber: "Number",
      incomeType: "Type",
      incomeStatus: "Status",
      description: "Description",
      paymentTypes: "Paid by",
      amount: "Amount",
      debit: "Debit",
      credit: "Credit",
      balance: "Balance",

      documentDate: "Document date",
      doctorName: "Doctor",
      payAmount: "Amount",
      paid: "Payed",
      treatmentIds: "Treatments",
      treatmentPlanIds: "Treatment plans",
    }
  }
}
