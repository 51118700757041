import type { App, Plugin } from "vue";
import 'flag-icons/css/flag-icons.min.css';
import 'v-phone-input/dist/v-phone-input.css';
import { createVPhoneInput } from 'v-phone-input';
// import 'vuetify/styles'; // this import breaks "primevue/dialog"
import { createVuetify } from 'vuetify'
import { VAutocomplete } from 'vuetify/components';

export const PhoneInputPlugin: Plugin = {
  install(app: App) {
    // https://paul-thebaud.github.io/v-phone-input
    // https://github.com/paul-thebaud/v-phone-input?tab=readme-ov-file#enabling-searching-countries
    // https://github.com/paul-thebaud/v-phone-input?tab=readme-ov-file#props-inheritance
    // https://stackoverflow.com/a/77105864/22548940
    const vPhoneInput = createVPhoneInput({
      countryIconMode: 'svg',
      displayFormat: "national",
      enableSearchingCountry: true
    });

    app.use(createVuetify(/*{ components: [VAutocomplete] }*/));
    app.component('VAutocomplete', VAutocomplete);
    app.use(vPhoneInput);
  }
}
