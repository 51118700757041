import type { DateTimeString } from "@/models/interfaces";
import type { PatientNoteSource } from "../enums";

export interface PatientNoteSchema {
  id: number,
  createdAt: DateTimeString,
  updatedAt: DateTimeString,
  deletedAt: DateTimeString,

  patientId: number,
  doctorId?: number,

  description: string,

  noteOnAppointmentCreation: boolean;
  noteOnIncomeCreation: boolean;
  noteOnPatientOpen: boolean;

  doctorName: string,
  sourceName: PatientNoteSource,
}

export const patientNoteSchemaAllFields = `
  id
  createdAt
  updatedAt
  deletedAt

  patientId
  doctorId

  description

  noteOnAppointmentCreation
  noteOnIncomeCreation
  noteOnPatientOpen

  doctorName
  sourceName
`;