export default {
  head: {
    title: "תבניות הודעות",
    meta: [
      { name: "description", content: "תיאור תבניות הודעה" }
    ]
  },
  table: {
    columns: {
      id: "ID",
      type: "סוג תבנית",
      platform: "פלטפורמה",
      systemName: "שם תבנית",
      active: "פעיל",
      autoSend: "שליחה אוטומטית"
    },
  },
  title: "תבניות הודעה",
  searchBySystemName: "חפש לפי תבנית הודעה",
  add: "הוספת תבנית הודעה"
}
