import GraphqlService from "../GraphqlService";
import { doctorFields } from "@/models/schemas";
import type { DoctorSchema, DoctorWorkingHoursSchema, DoctorNotWorkingDaysSchema, ServiceSchema } from "@/models/schemas";
import type { UpdateDoctorInput, CreateDoctorNotWorkingDaysInput, UpdateDoctorNotWorkingDaysInput, SaveDoctorWorkingHoursInput } from "@/models/api/mutations/DoctorModels";
import type { TabulatorParams, DateTimeString, TimeSpanString } from "@/models/interfaces";
import type { DayOfWeek, WorkingHoursType } from "@/models/enums";

const fieldsHours = `
  id
  createdAt
  updatedAt
  deletedAt
  userId
  day
  type
  fromHour
  toHour
`;

const fieldsDays = `
  id
  createdAt
  updatedAt
  deletedAt
  userId
  fromDate
  toDate
`;

export default class DoctorService {
  async getDoctors(params: TabulatorParams) {
    return await GraphqlService.getItems<DoctorSchema>("doctors", doctorFields, params);
  }

  async getDoctorsAll() {
    const { data } = await GraphqlService.queryGql<DoctorSchema[]>({
      method: "doctorsAll",
      fields: ["id", "displayName", "doctorImage"],
      order: [{ field: "displayName", value: "ASC" }]
    })
    return data ?? [];
  }

  readonly _calendarFields = [
    "id", "displayName", "doctorImage",
    "appointmentItemColor", "appointmentItemFontColor",
    "inactiveWorkHoursColor", "calendarHaveColors",
    "calendarGridMinutes", "calendarDayStart", "calendarDayEnd",
    "calendarAddAppointmentMinutes",
    "temporaryHoursEnabled", "temporaryHoursDateFrom", "temporaryHoursDateTo",
  ];

  async getDoctorsWithCalendars() {
    const { data } = await GraphqlService.queryGql<DoctorSchema[]>({
      method: "doctorsWithCalendars",
      fields: this._calendarFields,
      order: [{ field: "displayName", value: "ASC" }]
    })
    return data ?? [];
  }

  async getDoctorsWithCalendarsAll() {
    const { data } = await GraphqlService.queryGql<DoctorSchema[]>({
      method: "doctorsWithCalendarsAll",
      fields: this._calendarFields,
      order: [{ field: "displayName", value: "ASC" }]
    })
    return data ?? [];
  }

  async thisDoctorWithCalendar(doctorId: number) {
    const { data } = await GraphqlService.queryGql<DoctorSchema[]>({
      method: "doctorsWithCalendarsAll",
      fields: ["id", "haveCalendar"],
      filter: [{ field: "id", type: "=", value: doctorId }]
    })
    return data?.length ? data[0].haveCalendar : false;
  }

  async getDoctor(id: number, fields?: string[]) {
    return await GraphqlService.getItem<DoctorSchema>("doctor", fields ?? doctorFields, id);
  }

  async getMyDoctor() {
    const { data } = await GraphqlService.queryGql<DoctorSchema>({ method: "myDoctor", fields: doctorFields })
    return data;
  }

  async checkDoctorAvailability(id: number, options: { fromDate: DateTimeString, toDate?: DateTimeString, duration?: TimeSpanString }) {
    const { data } = await GraphqlService.queryGql<boolean>({
      method: "checkDoctorAvailability",
      variables: [
        { field: "id", value: id, valueType: "ID!" },
        { field: "fromDate", value: options.fromDate, valueType: "DateTimeType!" },
        { field: "toDate", value: options.toDate, valueType: "DateTimeType" },
        { field: "duration", value: options.duration, valueType: "TimeSpan" },
      ]
    });
    return data ?? false;
  }

  async updateDoctor(input: UpdateDoctorInput) {
    return await GraphqlService.setItem<DoctorSchema>("updateDoctor", "doctor", doctorFields, input);
  }

  async updateDoctorAvatar(id: number, fileName: string) {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "updateDoctorAvatar",
      variables: [
        { field: "id", value: id, valueType: "ID!" },
        { field: "fileName", value: fileName, valueType: "String!" }
      ]
    });
    return data ?? false;
  }

  async updateDoctorTemporaryHours(id: number, temporaryHoursEnabled: boolean, dateFrom: DateTimeString | null, dateTo: DateTimeString | null) {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "updateDoctorTemporaryHours",
      variables: [
        { field: "id", value: id, valueType: "ID!" },
        { field: "temporaryHoursEnabled", value: temporaryHoursEnabled, valueType: "Boolean!" },
        { field: "dateFrom", value: dateFrom, valueType: "DateTimeType" },
        { field: "dateTo", value: dateTo, valueType: "DateTimeType" },
      ]
    });
    return data ?? false;
  }

  //#region DoctorWorkingHoursSchema
  async getDoctorsWorkingHours(ids: number[]) {
    const { data } = await GraphqlService.queryGql<DoctorWorkingHoursSchema[]>({
      method: "doctorsWorkingHours",
      fields: fieldsHours,
      variables: [{ field: "ids", value: ids, valueType: "[ID!]" }]
    });
    return data ?? [];
  }

  async saveDoctorWorkingHours(input: SaveDoctorWorkingHoursInput) {
    return await GraphqlService.setItem<DoctorWorkingHoursSchema>("saveDoctorWorkingHours", "doctorWorkingHours", fieldsHours, input);
  }

  async disableDoctorWorkingHours(userId: number, day: DayOfWeek, type: WorkingHoursType, disable: boolean) {
    const { data } = await GraphqlService.mutateGql<number>({
      method: "disableDoctorWorkingHours",
      variables: [
        { field: "userId", value: userId, valueType: "ID!" },
        { field: "day", value: day, valueType: "DayOfWeek!" },
        { field: "type", value: type, valueType: "WorkingHoursType!" },
        { field: "disable", value: disable, valueType: "Boolean!" },
      ]
    });
    return data ?? 0;
  }

  async deleteDoctorWorkingHours(id: number, userId: number) {
    return await GraphqlService.deleteItem("deleteDoctorWorkingHours", id, {
      variables: [{ field: "userId", value: userId, valueType: "ID!" }]
    });
  }
  //#endregion

  //#region DoctorNotWorkingDays
  async getDoctorNotWorkingDays(id: number, params: TabulatorParams) {
    return await GraphqlService.getItems<DoctorNotWorkingDaysSchema>("doctorNotWorkingDays", fieldsDays, params, {
      variables: [{ field: "id", value: id, valueType: "ID!" }]
    });
  }

  async getDoctorNotWorkingDaysAll(ids: number[]) {
    const { data } = await GraphqlService.queryGql<DoctorNotWorkingDaysSchema[]>({
      method: "doctorNotWorkingDaysAll",
      fields: fieldsDays,
      variables: [{ field: "ids", value: ids, valueType: "[ID!]" }]
    });
    return data ?? [];
  }

  async createDoctorNotWorkingDays(input: CreateDoctorNotWorkingDaysInput) {
    return await GraphqlService.setItem<DoctorNotWorkingDaysSchema>("createDoctorNotWorkingDays", "doctorNotWorkingDays", fieldsDays, input);
  }

  async updateDoctorNotWorkingDays(input: UpdateDoctorNotWorkingDaysInput) {
    return await GraphqlService.setItem<DoctorNotWorkingDaysSchema>("updateDoctorNotWorkingDays", "doctorNotWorkingDays", fieldsDays, input);
  }

  async deleteDoctorNotWorkingDays(id: number, userId: number) {
    return await GraphqlService.deleteItem("deleteDoctorNotWorkingDays", id, {
      variables: [{ field: "userId", value: userId, valueType: "ID!" }]
    });
  }
  //#endregion

  //#region Services
  async getDoctorServicesAll(doctorId: number) {
    const { data } = await GraphqlService.queryGql<ServiceSchema[]>({
      method: "doctorServicesAll",
      fields: ["id", "categoryName", "name", "active"],
      variables: [{ field: "id", value: doctorId, valueType: "ID!" }]
    })
    return data ?? [];
  }

  async selectDoctorService(doctorId: number, serviceId: number) {
    return await GraphqlService.deleteItem("selectDoctorService", doctorId, {
      variables: [{ field: "serviceId", value: serviceId, valueType: "ID!" }],
    });
  }

  async deselectDoctorService(doctorId: number, serviceId: number) {
    return await GraphqlService.deleteItem("deselectDoctorService", doctorId, {
      variables: [{ field: "serviceId", value: serviceId, valueType: "ID!" }],
    });
  }

  async selectDoctorServices(doctorId: number, serviceIds: number[]) {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "selectDoctorServices",
      variables: [
        { field: "id", value: doctorId, valueType: "ID!" },
        { field: "serviceIds", value: serviceIds, valueType: "[Int!]" },
      ]
    });
    return data ?? false;
  }

  async deselectDoctorServices(doctorId: number, serviceIds: number[]) {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "deselectDoctorServices",
      variables: [
        { field: "id", value: doctorId, valueType: "ID!" },
        { field: "serviceIds", value: serviceIds, valueType: "[Int!]" },
      ]
    });
    return data ?? false;
  }
  //#endregion
}
