import type { DateTimeString } from "@/models/interfaces";
import type { TreatmentStageStatus } from "@/models/enums";

export interface TreatmentStatusSchema {
  id: number;
  createdAt: DateTimeString;
  updatedAt: DateTimeString;
  deletedAt: DateTimeString | null;

  title: string;
  color: string;
  stage: TreatmentStageStatus;
}

export class TreatmentStatusName {
  /** Not yet done */
  public static id1 = "טרם בוצע";
  /** In process */
  public static id2 = "בתהליך";
  /** Canceled */
  public static id3 = "מבוטל";
  /** Approved */
  public static id4 = "מאושר";
  /** Waiting */
  public static id5 = "ממתין";
  /** Finished */
  public static id6 = "הסתיים";
  /** Transferred */
  public static id7 = "הועבר";
  /** Rejected by an insurance company */
  public static id8 = "נדחה ע״י חברת ביטוח";
  /** Follow up */
  public static id9 = "מעקב";
}

export const treatmentStatusSchemaAllFields = [
  "id",
  "createdAt",
  "updatedAt",
  "deletedAt",
  "title",
  "color",
  "stage",
  "order"
];
