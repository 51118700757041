<template>
  <div class="p-formkit">
    <v-phone-input
      ref="phoneInstance"
      v-model="input.phone"
      @change="changePhoneNumber($event)"
      variant="outlined"
      persistentPlaceholder
      singleLine
      hideDetails
      density="compact"
      :defaultCountry="defaultCountry"
      :disabled="disabled" />
  </div>
</template>

<!-- https://paul-thebaud.github.io/v-phone-input -->

<script setup lang="ts">
  import { ref, reactive, computed, watch, onMounted, nextTick } from "vue";
  import { useI18n } from "vue-i18n";
  import { useStores } from "@/stores";

  const props = defineProps<{
    context: any;
  }>();

  const { locale } = useI18n();
  const stores = useStores();

  const context = props.context;
  const attrs = computed(() => context?.attrs);

  const phoneInstance = ref<InstanceType<any>>();
  const phoneElement = computed(() => phoneInstance.value?.$el as HTMLDivElement);
  const disabled = computed(() => attrs.value._disabled ?? context.disabled);
  const defaultCountry = ref(stores.settings.getValue<string>("DefaultCountry", "IL"));

  const input = reactive({
    phone: null as string | null,
    valid: false
  });

  watch(
    () => context._value,
    (v1, v2) => setValue(v1, v2)
  );

  function setValue(valueNew: string | null, valueOld: string | null) {
    if (valueNew && !valueOld) {
      const phoneNew = valueNew.startsWith("+") ? valueNew : `+${valueNew}`;
      if (phoneNew === input.phone) return;
      nextTick(async () => {
        input.phone = phoneNew;
        // remove invalid phone code #1525
        await nextTick();
        await nextTick();
        if (!isValid() && phoneNew.startsWith("+972")) {
          input.phone = "0" + phoneNew.substring(3);
        }
      });
    }
  }

  function isValid() {
    return !phoneElement.value.querySelector(".v-input--error"); // .v-field--error;
  }

  function changePhoneNumber(e: any) {
    input.valid = isValid();
    if (input.valid) {
      if (input.phone) {
        const phone = input.phone?.startsWith("+") ? input.phone.substring(1) : input.phone;
        context?.node.input(phone);
      } else {
        context?.node.input(null);
      }
    } else if (input.phone) {
      const phoneInvalid = input.phone.startsWith("+") ? input.phone : "+" + input.phone; // '+' not stored in the database
      context?.node.input(phoneInvalid);
    } else {
      context?.node.input(null);
    }
  }

  function validationStringToArray(value: string) {
    return value
      .split("|")
      .filter(Boolean)
      .map((x) => {
        let index = x.indexOf(":");
        if (index < 0) return [x];
        return [x.substring(0, index)].concat(x.substring(index + 1).split(","));
      });
  }
  function getValidation() {
    let array = [];
    if (typeof context.node.props.validation === "string") {
      array = validationStringToArray(context.node.props.validation);
    } else {
      array = context.node.props.validation || [];
    }
    // add phone validation
    array.push(["matches", /^\d{11,16}$/]);
    // console.log(array);
    return array;
  }

  onMounted(() => {
    context.node.props.validation = getValidation();
    if (context._value) setValue(context._value, input.phone);
    // fix menu width
    phoneElement.value.querySelector(".v-autocomplete")?.addEventListener("click", () =>
      nextTick(() => {
        const menu = document.querySelector<HTMLElement>(".v-overlay-container .v-phone-input__country__menu");
        if (menu) {
          menu.style.width = phoneElement.value.offsetWidth + "px";
        }
      })
    );
    // form-control
    phoneElement.value.classList.add("input-group");
    phoneElement.value.querySelector(".v-phone-input__country__input div.v-input__control")!.classList.add("form-control", "p-0");
    phoneElement.value.querySelector(".v-input__control input.v-field__input")!.classList.add("form-control", "border-0");
  });
</script>

<style lang="scss">
  :root {
    --v-theme-overlay-multiplier: 1;
  }

  .v-phone-input {
    [dir="rtl"] & {
      flex-direction: row-reverse;

      .v-phone-input__country__input {
        .v-field.v-locale--is-ltr {
          direction: ltr;
        }
      }
    }

    .v-input--error {
      border-color: #f35257;
      background-color: rgba(255, 73, 76, 0.06);
      outline: none;
    }
    .v-phone-input__country__input.v-input:not(.v-autocomplete.v-phone-input--focused) .v-field.v-field--variant-outlined {
      --v-phone-input-country-width: 3rem !important;
    }

    .v-phone-input__country__input.v-autocomplete {
      .v-field__outline {
        .v-field__outline__start,
        .v-field__outline__end {
          border: 0;
        }
      }

      .v-field__input {
        padding-top: 4px;
        padding-bottom: 4px;

        input {
          background: unset;
          border: none;
        }
      }

      &.v-phone-input--focused {
        --v-phone-input-country-autocomplete-width: 50%;
      }
      .v-field--focused {
        .v-field__append-inner {
          // margin: 0 !important;
        }
      }

      .v-field--appended {
        padding-inline-end: unset;

        .v-field__input {
          padding-left: unset;
          padding-inline-start: 4px;
          padding-right: unset;
          padding-inline-end: unset;

          .v-phone-input__country__icon {
            margin: 0;
          }
        }

        .v-field__append-inner {
          margin: 0 -2px 0 -8px;
        }

        .v-autocomplete__menu-icon {
          margin-left: unset;
          margin-inline-start: unset;
        }
      }
    }

    .v-phone-input__phone__input {
      input {
        margin-top: 1px;
      }
    }
  }

  .v-overlay-container {
    .v-phone-input__country__menu {
      [dir="rtl"] & {
        .v-list-item {
          direction: rtl;
        }
      }
    }
  }
</style>
