import type { DateTimeString, TimeSpanString } from "@/models/interfaces";
import type { AppointmentStatus } from "@/models/enums";

export interface AppointmentSchema {
  id: number;
  createdAt: DateTimeString;
  updatedAt: DateTimeString;
  deletedAt?: DateTimeString;

  color: string;
  startTime: DateTimeString;
  duration: TimeSpanString;
  userId: number;
  serviceId?: number;
  /** Returns name by ServiceId, otherwise ServiceName field */
  serviceName: string;
  note?: string;
  titleSync?: string;
  status: AppointmentStatus;
  patientId?: number;

  cancellationReason?: string | null;
  cancellationDate?: DateTimeString | null;
  cancellationUserId?: number | null;

  cancellationUserName?: string;
  doctorImage?: string;
  doctorName: string;
  serviceDescription: string;
  patientName: string;
  patientPhone: string;

  googleCalendarId?: string;
  microsoftCalendarId?: string;
}

export const appointmentSchemaAllFields = `
  id
  createdAt
  updatedAt
  deletedAt

  color
  startTime
  duration
  userId
  serviceId
  serviceName
  note
  titleSync
  status
  patientId

  cancellationReason
  cancellationDate
  cancellationUserId

  cancellationUserName
  doctorImage
  doctorName
  serviceDescription
  patientName
  patientPhone

  googleCalendarId
  microsoftCalendarId
`;
