<template>
  <div class="p-formkit">
    <div class="input-group">
      <input
        class="form-control"
        :class="styleClass"
        :type="show ? 'text' : 'password'"
        v-model="context._value"
        v-bind="context.attrs"
        @input="handleInput"
        @blur="handleBlur" />
      <div class="input-group-text bg-white" @click="show = !show" role="button">
        <i class="password-hover-icon mdi fs-5" :class="show ? 'mdi-eye-off' : 'mdi-eye'" />
      </div>
    </div>
  </div>
</template>

<!-- https://github.com/sfxcode/formkit-primevue/blob/prime3/src/components/PrimePassword.vue -->

<script setup lang="ts">
  import { ref, type PropType, computed } from "vue";
  import type { FormKitFrameworkContext } from "@formkit/core";

  const props = defineProps({
    context: {
      type: Object as PropType<FormKitFrameworkContext>,
      required: true
    }
  });

  const show = ref(false);

  function handleBlur(e: FocusEvent) {
    props.context?.handlers.blur(e);
  }

  function handleInput(e: any) {
    props.context?.node.input(e.target.value);
  }

  const styleClass = computed(() =>
    props.context?.state.validationVisible && !props.context?.state.valid ? `${props.context?.attrs?.class} p-invalid` : props.context?.attrs?.class
  );
</script>

<style scoped lang="scss">
  .input-group-text {
    padding-top: 0.125rem;
    padding-bottom: 0;
  }
</style>
