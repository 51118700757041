<template>
  <div class="p-formkit">
    <BDropdown
      v-bind="context.attrs"
      :class="styleClass"
      auto-close="outside"
      variant="outline"
      toggle-class="btn-outline-light"
      class="dropdown--select-style"
      :text="props.context.label">
      <template #button-content>
        {{ props.context._value }}
      </template>
      <BDropdownText class="teeth-surface-w">
        <div class="teeth-surface">
          <svg xmlns="http://www.w3.org/2000/svg" width="132" height="132" viewBox="0 0 132 132">
            <path
              class="layer"
              :class="{ 'is-active': surfaceOptions[0].value }"
              @click="toggleBox(0)"
              id="layer-m"
              d="M42.5,43.06l-24-24L1,36.36v59.2l17.5,17.5,24-24L34,80.56v-29Z" />
            <path
              class="layer"
              :class="{ 'is-active': surfaceOptions[1].value }"
              @click="toggleBox(1)"
              id="layer-l"
              d="M42.9,89.56l-24,24,17.3,17.5H95.4l17.5-17.5-24-24-8.5,8.5h-29Z" />
            <path
              class="layer"
              :class="{ 'is-active': surfaceOptions[2].value }"
              @click="toggleBox(2)"
              id="layer-d"
              d="M89.5,89.06l24,24L131,95.76V36.56l-17.5-17.5-24,24,8.5,8.5v29Z" />
            <path
              class="layer"
              :class="{ 'is-active': surfaceOptions[3].value }"
              @click="toggleBox(3)"
              id="layer-b"
              d="M88.9,42.46l24-24L95.6,1H36.4L18.9,18.46l24,24L51.4,34h29Z" />
            <path
              class="layer"
              :class="{ 'is-active': surfaceOptions[4].value }"
              @click="toggleBox(4)"
              id="layer-i"
              d="M34.5 51.6617L51.6617 34.5H80.3383L97.5 51.6617V80.3383L80.3383 97.5H51.6617L34.5 80.3383V51.6617Z" />
            <path
              class="layer"
              :class="{ 'is-active': surfaceOptions[5].value }"
              @click="toggleBox(5)"
              id="layer-o"
              d="M67.5 34.5H80.3383L97.5 51.6617V80.3383L80.3383 97.5H67.5005L67.5 80.5454V51.4545V34.5Z"
              stroke="#bec1ce" />

            <path
              @click="toggleBox(3)"
              d="M61.62,22a.32.32,0,0,1-.33-.31V12.55a.33.33,0,0,1,.08-.25.35.35,0,0,1,.25-.1h4.06a3.54,3.54,0,0,1,2.45.73,2.48,2.48,0,0,1,.81,2,2.11,2.11,0,0,1-.36,1.24,2,2,0,0,1-.83.73,2.44,2.44,0,0,1,1.4,2.24,2.68,2.68,0,0,1-.88,2.1,3.55,3.55,0,0,1-2.46.8Zm3.81-5.78a1.56,1.56,0,0,0,1.09-.35,1.22,1.22,0,0,0,.39-.94,1.13,1.13,0,0,0-.37-.91,1.69,1.69,0,0,0-1.11-.32H63.26v2.52Zm.14,4.28a1.52,1.52,0,0,0,1.13-.39,1.39,1.39,0,0,0,.41-1,1.31,1.31,0,0,0-.42-1,1.54,1.54,0,0,0-1.12-.41H63.26v2.8Z"
              style="fill: #51597b" />
            <path
              @click="toggleBox(1)"
              d="M62.86,119a.34.34,0,0,1-.34-.34v-9.12a.31.31,0,0,1,.1-.24.35.35,0,0,1,.24-.1h1.32a.25.25,0,0,1,.23.1.28.28,0,0,1,.1.24v7.78H69a.3.3,0,0,1,.25.1.31.31,0,0,1,.1.24v1a.31.31,0,0,1-.1.24.3.3,0,0,1-.25.1Z"
              style="fill: #51597b" />
            <path
              @click="toggleBox(2)"
              d="M111.51,70a.34.34,0,0,1-.34-.34V60.55a.44.44,0,0,1,.08-.25.32.32,0,0,1,.26-.1h3.41c2.76,0,4.16,1.27,4.22,3.81v2.17a4,4,0,0,1-1.07,2.9A4.41,4.41,0,0,1,115,70Zm3.41-1.62a2.32,2.32,0,0,0,1.68-.52,2.44,2.44,0,0,0,.55-1.72v-2a2.31,2.31,0,0,0-.58-1.68,2.35,2.35,0,0,0-1.72-.55h-1.71v6.56Z"
              style="fill: #51597b" />
            <path
              @click="toggleBox(4)"
              d="M51.7815 71C51.6815 71 51.5965 70.965 51.5265 70.895C51.4565 70.825 51.4215 70.74 51.4215 70.64V60.86C51.4215 60.75 51.4565 60.665 51.5265 60.605C51.5965 60.535 51.6815 60.5 51.7815 60.5H53.2215C53.3315 60.5 53.4165 60.535 53.4765 60.605C53.5465 60.665 53.5815 60.75 53.5815 60.86V70.64C53.5815 70.74 53.5465 70.825 53.4765 70.895C53.4165 70.965 53.3315 71 53.2215 71H51.7815Z"
              style="fill: #51597b" />
            <path
              @click="toggleBox(0)"
              d="M12.75,70a.34.34,0,0,1-.34-.34V60.55a.38.38,0,0,1,.09-.25.3.3,0,0,1,.25-.1h1.13a.46.46,0,0,1,.42.27l2.7,5,2.71-5a.46.46,0,0,1,.42-.27h1.12a.35.35,0,0,1,.25.1.39.39,0,0,1,.1.25v9.11a.35.35,0,0,1-.1.26.44.44,0,0,1-.25.08H20a.32.32,0,0,1-.33-.31v-6l-2,3.7a.49.49,0,0,1-.46.31h-.59a.53.53,0,0,1-.48-.31l-1.94-3.7v6a.35.35,0,0,1-.1.24A.31.31,0,0,1,14,70Z"
              style="fill: #51597b" />
            <path
              @click="toggleBox(5)"
              d="M82.5052 71.15C81.1352 71.15 80.0702 70.805 79.3102 70.115C78.5502 69.425 78.1502 68.4 78.1102 67.04C78.1002 66.75 78.0952 66.325 78.0952 65.765C78.0952 65.195 78.1002 64.765 78.1102 64.475C78.1502 63.145 78.5602 62.125 79.3402 61.415C80.1202 60.705 81.1752 60.35 82.5052 60.35C83.8252 60.35 84.8752 60.705 85.6552 61.415C86.4452 62.125 86.8602 63.145 86.9002 64.475C86.9202 65.055 86.9302 65.485 86.9302 65.765C86.9302 66.035 86.9202 66.46 86.9002 67.04C86.8602 68.4 86.4552 69.425 85.6852 70.115C84.9252 70.805 83.8652 71.15 82.5052 71.15ZM82.5052 69.41C83.1752 69.41 83.7052 69.21 84.0952 68.81C84.4852 68.41 84.6952 67.795 84.7252 66.965C84.7452 66.365 84.7552 65.96 84.7552 65.75C84.7552 65.52 84.7452 65.115 84.7252 64.535C84.6952 63.715 84.4802 63.105 84.0802 62.705C83.6802 62.295 83.1552 62.09 82.5052 62.09C81.8452 62.09 81.3152 62.295 80.9152 62.705C80.5152 63.105 80.3052 63.715 80.2852 64.535C80.2752 64.825 80.2702 65.23 80.2702 65.75C80.2702 66.26 80.2752 66.665 80.2852 66.965C80.3052 67.785 80.5102 68.4 80.9002 68.81C81.3002 69.21 81.8352 69.41 82.5052 69.41Z"
              style="fill: #51597b" />

            <path
              d="M114.35,18.35h0l-.7-.7h0L96,0H36L0,36V96l17.65,17.65.7.7L36,132H96l17.65-17.65h0l.7-.7h0L132,96V36ZM36.41,1H95.59l17.35,17.35-24,24L80.54,34H51.45l-8.38,8.39-24-24ZM80.13,97H51.87L35,80.13V51.87L51.87,35H80.13L97,51.87V80.13ZM1,95.59V36.41L18.34,19.07l24,24L34,51.45V80.54l8.37,8.38-24,24ZM95.59,131H36.41L19.06,113.65l24-24L51.45,98H80.54l8.38-8.37,24,24ZM131,95.59l-17.35,17.35-24-24L98,80.54V51.45l-8.37-8.37,24-24L131,36.41Z"
              style="fill: #bec1ce" />
          </svg>
        </div>
      </BDropdownText>
    </BDropdown>
  </div>
</template>

<script setup lang="ts">
  import { ref, computed, watch, onMounted, type PropType } from "vue";
  import type { FormKitFrameworkContext } from "@formkit/core";

  const props = defineProps({
    context: {
      type: Object as PropType<FormKitFrameworkContext>,
      required: true
    }
  });

  function setValue(value: string | null) {
    const areas = value?.split(",").map((x) => x.toUpperCase()) ?? [];
    surfaceOptions.value.forEach((x) => (x.value = areas.includes(x.label)));
  }
  watch(
    () => props.context._value,
    (v1: string) => setValue(v1)
  );

  onMounted(() => {
    setValue(props.context._value);
  });

  const surfaceOptions = ref([
    { label: "M", value: false },
    { label: "L", value: false },
    { label: "D", value: false },
    { label: "B", value: false },
    { label: "I", value: false },
    { label: "O", value: false }
  ]);

  const surfaceSelectedOptions = computed(() => {
    return surfaceOptions.value
      .filter((el) => el.value)
      .map((el) => el.label)
      .join(",");
  });

  function toggleBox(index: number) {
    surfaceOptions.value[index].value = !surfaceOptions.value[index].value;
    props.context.node.input(surfaceSelectedOptions.value);
  }

  const styleClass = computed(() =>
    props.context.state.validationVisible && !props.context.state.valid ? `${props.context.attrs?.class} p-invalid` : props.context.attrs?.class
  );
</script>

<style lang="scss" scoped>
  .teeth-surface-w {
    margin: 1px;
    padding: 1px;
  }
  .teeth-surface {
    margin: 1px;
    padding: 1px;

    svg {
      margin: 1px;
      padding: 1px;

      .layer {
        cursor: pointer;
        fill: #f1f3fc;
        &.is-active {
          fill: #cde0fc;
        }
      }
    }
  }
</style>
