import { i18n } from "@/locales/i18n";
import { useStores } from "@/stores";
import { Policies } from "@/models/api/identity/Policies";
import type { RouteRecordRaw } from "vue-router";

const routes: RouteRecordRaw[] = [
  //#region databases
  {
    path: "/databases/login",
    name: "databases_login",
    component: () => import("@/views/databases/login.vue"),
  },
  {
    path: "/databases",
    name: "databases",
    component: () => import("@/views/databases/databases.vue"),
    meta: {
      beforeResolve(routeTo: any, routeFrom: any, next: any) {
        if (!useStores().database.password) { next({ name: "databases_login" }); } else { next(); }
      }
    }
  },
  {
    path: "/database/:id",
    name: "database",
    component: () => import("@/views/databases/database.vue"),
    meta: {
      beforeResolve(routeTo: any, routeFrom: any, next: any) {
        if (!useStores().database.password) { next({ name: "databases_login" }); } else { next(); }
      }
    }
  },
  //#endregion

  //#region account
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/account/login.vue"),
  },
  {
    path: "/password-reset",
    name: "password_reset",
    component: () => import("@/views/account/password-reset.vue"),
  },
  {
    path: "/password-change",
    name: "password_change",
    component: () => import("@/views/account/password-change.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("@/views/account/logout.vue"),
  },
  //#endregion

  //#region pages
  {
    path: "/404",
    name: "error_404",
    meta: { authRequired: true },
    component: () => import("@/views/pages/404.vue"),
  },
  {
    path: "/500",
    name: "error_500",
    meta: { authRequired: true },
    component: () => import("@/views/pages/500.vue"),
  },
  {
    path: "/502",
    name: "error_502",
    meta: { authRequired: true },
    component: () => import("@/views/pages/502.vue"),
  },
  // #endregion

  //#region dashboard
  {
    path: "/",
    name: "default",
    meta: { authRequired: true },
    component: () => import("@/views/dashboard/default.vue"),
  },
  //#endregion

  //#region calendar
  {
    path: "/calendar",
    name: "calendar",
    meta: { authRequired: true, policy: Policies.Appointments },
    redirect: "/calendar/week",
    children: [{
      path: '/calendar/:id(day)',
      name: 'calendar_types_day',
      component: () => import("@/views/calendar/types/calendar-day.vue"),
    },
    {
      path: '/calendar/:id(week)',
      name: 'calendar_types_week',
      component: () => import("@/views/calendar/types/calendar-week.vue"),
    },
    {
      path: '/calendar/:id(list)',
      name: 'calendar_types_list',
      component: () => import("@/views/calendar/types/calendar-list.vue"),
    },
    {
      path: '/calendar/:id(search)',
      name: 'calendar_types_search',
      component: () => import("@/views/calendar/types/calendar-search.vue"),
    },
    {
      path: '/calendar/:id',
      redirect: "/404"
    }],
    component: () => import("@/views/calendar/calendar.vue"),
  },
  {
    path: "/calendar/waitlist/:id?",
    name: "calendar_waitlist",
    meta: { authRequired: true, policy: Policies.Appointments },
    component: () => import("@/views/calendar/waitlist-list.vue"),
  },
  {
    path: "/calendar/reminder",
    name: "calendar_reminder",
    meta: { authRequired: true, policy: Policies.Appointments },
    component: () => import("@/views/calendar/appointment-reminder-list.vue"),
  },
  //#endregion

  //#region patients
  {
    path: "/patients",
    name: "patients",
    meta: { authRequired: true, policy: Policies.Patients },
    component: () => import("@/views/patients/patients-list.vue"),
  },
  {
    path: "/patient/:id",
    name: "patient",
    meta: { authRequired: true, policy: Policies.Patients },
    component: () => import("@/views/patients/patient.vue"),
  },
  {
    path: "/patients/:id/treatments",
    name: "patient_treatments",
    meta: { authRequired: true, policy: Policies.Patients },
    component: () => import("@/views/patients/treatments/treatment.vue"),
  },
  //#endregion

  //#region chat
  {
    path: "/chat/:phone?",
    name: "chat",
    meta: { authRequired: true, policy: Policies.Chat },
    component: () => import("@/views/chat/index.vue"),
  },
  {
    path: "/chat-settings",
    name: "chat_settings",
    meta: { authRequired: true, policy: Policies.Settings },
    component: () => import("@/views/chat/settings.vue"),
  },
  {
    path: "/chat-settings-sms",
    name: "chat_settings_sms",
    meta: { authRequired: true, policy: Policies.Settings },
    component: () => import("@/views/chat/settings-sms.vue"),
  },
  //#endregion

  //#region email
  {
    path: "/email/:email?",
    name: "email",
    meta: { authRequired: true, policy: Policies.Emails },
    component: () => import("@/views/email/inbox.vue"),
  },
  {
    path: "/email/reademail/:id",
    name: "email_read",
    meta: { authRequired: true, policy: Policies.Emails },
    component: () => import("@/views/email/reademail.vue"),
  },
  {
    path: "/email/settings",
    name: "email_settings",
    meta: { authRequired: true, policy: Policies.Emails },
    component: () => import("@/views/email/email-setting.vue"),
  },
  {
    path: "/email/send",
    name: "email_send",
    meta: { authRequired: true, policy: Policies.Emails },
    component: () => import("@/views/email/email-send.vue"),
  },
  //#endregion

  //#region leads
  {
    path: "/leads/:id?",
    name: "leads",
    meta: { authRequired: true, policy: Policies.Leads },
    component: () => import("@/views/leads/leads-list.vue"),
  },
  {
    path: "/leads/channels/:id?",
    name: "lead_channels",
    meta: { authRequired: true, policy: Policies.Leads },
    component: () => import("@/views/leads/channels.vue"),
  },
  //#endregion

  //#region recalls
  {
    path: "/recalls",
    name: "recalls",
    meta: { authRequired: true, policy: Policies.Recall },
    component: () => import("@/views/recalls/recalls-list.vue"),
  },
  //#endregion

  //#region financial

  // incomes
  {
    path: "/incomes",
    name: "incomes",
    meta: { authRequired: true, policy: Policies.Incomes },
    component: () => import("@/views/incomes/incomes.vue"),
  },
  {
    path: "/income/:type",
    name: "income",
    meta: {
      authRequired: true,
      preventUnload: '<i class="mdi mdi-alert-circle-outline me-2"></i>' + i18n.global.t("incomes.income.backPopup.text"),
    },
    component: () => import("@/views/incomes/income.vue"),
  },

  // expenses
  {
    path: "/expenses",
    name: "expenses",
    meta: { authRequired: true, policy: Policies.Expenses },
    component: () => import("@/views/expenses/expenses.vue"),
  },
  {
    path: "/expenses/suppliers",
    name: "expenses_suppliers",
    meta: { authRequired: true, policy: Policies.Expenses },
    component: () => import("@/views/expenses/suppliers.vue"),
  },
  {
    path: "/expenses/types",
    name: "expenses_types",
    meta: { authRequired: true, policy: Policies.Expenses },
    component: () => import("@/views/expenses/types.vue"),
  },

  // deposit
  {
    path: "/deposit/cash",
    name: "deposit_cash",
    meta: { authRequired: true, policy: Policies.Deposit },
    component: () => import("@/views/deposit/cash.vue"),
  },
  {
    path: "/deposit/checks",
    name: "deposit_checks",
    meta: { authRequired: true, policy: Policies.Deposit },
    component: () => import("@/views/deposit/checks.vue"),
  },

  // forecast
  {
    path: "/forecast",
    name: "forecast",
    meta: { authRequired: true, policy: Policies.Forecast },
    component: () => import("@/views/forecast/forecasts.vue"),
  },

  // reports
  {
    path: "/reports/overview",
    name: "reports_overview",
    meta: { authRequired: true, policy: Policies.FinancialReports },
    component: () => import("@/views/reports/overview.vue"),
  },
  {
    path: "/reports/collection",
    name: "reports_collection",
    meta: { authRequired: true, policy: Policies.FinancialReports },
    component: () => import("@/views/reports/collection.vue"),
  },
  {
    path: "/reports/income",
    name: "reports_income",
    meta: { authRequired: true, policy: Policies.FinancialReports },
    component: () => import("@/views/reports/income.vue"),
  },
  {
    path: "/reports/expenses",
    name: "reports_expenses",
    meta: { authRequired: true, policy: Policies.FinancialReports },
    component: () => import("@/views/reports/expenses.vue"),
  },
  {
    path: "/reports/profit-and-loss",
    name: "reports_profit_and_loss",
    meta: { authRequired: true, policy: Policies.FinancialReports },
    component: () => import("@/views/reports/profit-and-loss.vue"),
  },
  {
    path: "/reports/vat",
    name: "reports_vat",
    meta: { authRequired: true, policy: Policies.FinancialReports },
    component: () => import("@/views/reports/vat.vue"),
  },
  {
    path: "/reports/advance-tax",
    name: "reports_advance_tax",
    meta: { authRequired: true, policy: Policies.FinancialReports },
    component: () => import("@/views/reports/advance-tax.vue"),
  },
  {
    path: "/reports/between-businesses",
    name: "reports_between_businesses",
    meta: { authRequired: true, policy: Policies.AccountingReport },
    component: () => import("@/views/reports/accounting/index.vue"),
  },
  //#endregion

  //#region tasks
  {
    path: "/tasks/:id?",
    name: "tasks",
    meta: { authRequired: true, policy: Policies.Tasks },
    component: () => import("@/views/tasks/tasks-list.vue"),
  },
  //#endregion

  //#region laboratoryWorks
  {
    path: "/lab-works/:id?",
    name: "lab_works",
    meta: { authRequired: true, policy: Policies.LaboratoryWorks },
    component: () => import("@/views/lab-works/laboratory-works-list.vue"),
  },
  {
    path: "/lab-works/laboratories",
    name: "lab_works_laboratories",
    meta: { authRequired: true, policy: Policies.LaboratoryWorks },
    component: () => import("@/views/lab-works/laboratories.vue"),
  },
  {
    path: "/lab-works/laboratory/:id",
    name: "lab_works_laboratory",
    meta: { authRequired: true, policy: Policies.LaboratoryWorks },
    component: () => import("@/views/lab-works/laboratory.vue"),
  },
  //#endregion

  //#region attendance
  {
    path: "/attendance",
    name: "attendance",
    meta: { authRequired: true, policy: Policies.Attendance },
    component: () => import("@/views/attendance/attendance.vue"),
  },
  {
    path: "/attendance/report",
    name: "attendance_report",
    meta: { authRequired: true, policy: Policies.Attendance },
    component: () => import("@/views/attendance/attendance-report-view.vue"),
  },
  //#endregion

  //#region minisite
  {
    path: "/minisite",
    name: "minisite",
    meta: { authRequired: true },
    component: () => import("@/views/minisite/index.vue")
  },
  //#endregion

  //#region call center
  {
    path: "/call-center",
    name: "call_center",
    meta: { authRequired: true },
    component: () => import("@/views/call-center/index.vue")
  },
  //#endregion

  //#region settings

  // business
  {
    path: "/settings/business",
    name: "settings_business",
    meta: { authRequired: true, policy: Policies.Settings },
    component: () => import("@/views/settings/business/index.vue"),
  },

  // users
  {
    path: "/settings/users",
    name: "users",
    meta: { authRequired: true, policy: Policies.Users },
    component: () => import("@/views/settings/users/users.vue"),
  },
  {
    path: "/settings/users/:id",
    name: "user",
    meta: { authRequired: true },
    component: () => import("@/views/settings/users/user.vue"),
  },

  // services
  {
    path: "/settings/services",
    name: "services",
    meta: { authRequired: true, policy: Policies.Settings },
    component: () => import("@/views/settings/services/services.vue"),
  },
  {
    path: "/settings/service/:type/:id",
    name: "service",
    meta: { authRequired: true, policy: Policies.Settings },
    component: () => import("@/views/settings/services/service.vue"),
  },
  {
    path: "/settings/services/categories",
    name: "service_categories",
    meta: { authRequired: true, policy: Policies.Settings },
    component: () => import("@/views/settings/services/categories.vue"),
  },
  {
    path: "/settings/services/category/:id",
    name: "service_category",
    meta: { authRequired: true, policy: Policies.Settings },
    component: () => import("@/views/settings/services/category.vue"),
  },

  // pricelist
  {
    path: "/settings/pricelist",
    name: "pricelist",
    meta: { authRequired: true, policy: Policies.Settings },
    component: () => import("@/views/settings/pricelist/pricelist.vue"),
  },
  {
    path: "/settings/pricelist/groups",
    name: "pricelist_groups",
    meta: { authRequired: true, policy: Policies.Settings },
    component: () => import("@/views/settings/pricelist/groups.vue"),
  },
  {
    path: "/settings/pricelist/group/:id",
    name: "pricelist_group",
    meta: { authRequired: true, policy: Policies.Settings },
    component: () => import("@/views/settings/pricelist/group.vue"),
  },

  // templates documents
  {
    path: "/settings/templates/documents",
    name: "settings_templates_documents",
    meta: { authRequired: true, policy: Policies.Settings },
    component: () => import("@/views/settings/templates/documents.vue"),
  },
  {
    path: "/settings/templates/document/:id",
    name: "settings_templates_document",
    meta: { authRequired: true, policy: Policies.Settings },
    component: () => import("@/views/settings/templates/document.vue"),
  },

  // templates messages
  {
    path: "/settings/templates/messages",
    name: "settings_templates_messages",
    meta: { authRequired: true, policy: Policies.Settings },
    component: () => import("@/views/settings/templates/messages.vue"),
  },
  {
    path: "/settings/templates/message/:id",
    name: "settings_templates_message",
    meta: { authRequired: true, policy: Policies.Settings },
    component: () => import("@/views/settings/templates/message.vue"),
  },

  // templates prescriptions
  {
    path: "/settings/templates/prescriptions",
    name: "settings_templates_prescriptions",
    meta: { authRequired: true, policy: Policies.Settings },
    component: () => import("@/views/settings/templates/prescriptions.vue"),
  },
  {
    path: "/settings/templates/prescription/:id",
    name: "settings_templates_prescription",
    meta: { authRequired: true, policy: Policies.Settings },
    component: () => import("@/views/settings/templates/prescription.vue"),
  },

  // restore
  {
    path: "/settings/restore",
    name: "/settings_restore",
    meta: { authRequired: true, policy: Policies.Settings },
    component: () => import("@/views/settings/restore/index.vue")
  },

  //#endregion

  //#region other

  /* https://metanit.com/web/vue/6.8.php */
  {
    path: "/:path(.*)",
    name: "page404",
    component: () => import("@/views/pages/404.vue"),
  }

  /* https://github.com/vuejs/vue-router/issues/977 */
  // {
  //   path: "/:path(.*)",
  //   name: "page500",
  //   component: () => import("@/views/pages/500.vue"),
  // }

  //#endregion
];

export default routes;
