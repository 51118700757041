import moment from "@/plugins/VueMomentPlugin";
import gql from "graphql-tag";
import GraphqlService, { type Order } from "../GraphqlService";
import { useApolloClient } from "@vue/apollo-composable";
import { AppointmentStatus, MessageProvider } from "@/models/enums";
import { appointmentSchemaAllFields as fieldsAppointment } from "@/models/schemas";
import type { AppointmentSchema, DoctorSchema, AppointmentReminderSchema } from "@/models/schemas";
import type { DateTimeString, TimeSpanString, TabulatorParams, Result } from "@/models/interfaces";
import type { SaveAppointmentInput } from "@/models/api/mutations/AppointmentModels";
import type { FilterAppointmentInput } from "@/models/api/queries/AppointmentModels";

export default class AppointmentService {
  async getAppointments(params: TabulatorParams) {
    return await GraphqlService.getItems<AppointmentSchema>("appointments", fieldsAppointment, params);
  }

  async getAppointmentsWithoutWaitlist(fields: string[], filter?: { patientId?: number, fromDate?: DateTimeString, toDate?: DateTimeString }) {
    const { data } = await GraphqlService.queryGql<AppointmentSchema[]>({
      method: "appointmentsWithoutWaitlist",
      fields: fields,
      filter: [
        { field: "serviceId", type: "!=", value: "null" },
        { field: "patientId", type: filter?.patientId ? "=" : "!=", value: filter?.patientId ?? "null" },
        { field: "startTime", type: ">=", value: filter?.fromDate, valueType: "DateTime" },
        { field: "startTime", type: "<=", value: filter?.toDate, valueType: "DateTime" },
      ],
      order: [
        { field: "startTime", value: "ASC" }
      ]
    });
    return data ?? [];
  }

  async getDoctorsAppointments(input?: FilterAppointmentInput, fields?: string[], order?: Order[]) {
    const { data } = await GraphqlService.queryGql<AppointmentSchema[]>({
      method: "doctorsAppointments",
      fields: fields ?? fieldsAppointment,
      variables: [{ field: "input", value: input, valueType: "FilterAppointmentInput" }],
      order: order
    });
    return data ?? [];
  }

  async hasDoctorsAppointments(appointmentId: number, doctorId: number, fromDate: DateTimeString | Date, toDate: DateTimeString | Date) {
    const input = { appointmentId, doctorId, fromDate, toDate };
    const { data } = await GraphqlService.queryGql<boolean>({
      method: "hasDoctorsAppointments",
      variables: [{ field: "input", value: input, valueType: "HasAppointmentInput" }],
    });
    return data!;
  }

  async getAppointmentReminders(appointmentId: number) {
    const { data } = await GraphqlService.queryGql<AppointmentReminderSchema[]>({
      method: "appointmentReminders",
      fields: ["id", "createdAt", "type", "data"],
      variables: [
        { field: "appointmentId", value: appointmentId, valueType: "Int!" }
      ]
    });
    return data ?? [];
  }

  async getAppointment(id: number) {
    return await GraphqlService.getItem<AppointmentSchema>("appointment", fieldsAppointment, id);
  }

  async getTodayAppointments(limit: number, page: number, userIds: number[]) {
    const startTime = moment.utc().format("YYYY-MM-DD") + "Z";
    const endTime = moment.utc().add(1, 'day').format("YYYY-MM-DD") + "Z";

    const params: TabulatorParams = {
      size: limit,
      page: page,
      filter: [
        { field: "startTime", type: ">=", value: startTime, valueType: "DateTime!" },
        { field: "startTime", type: "<", value: endTime, valueType: "DateTime!" },
      ],
      sort: [{ field: "startTime", dir: "asc" }]
    }

    if (userIds.length) {
      params.filter.unshift({ field: "userId", type: "in", value: userIds, valueType: "[Int!]" })
    }

    return await GraphqlService.getItems<AppointmentSchema>("appointments", fieldsAppointment, params);
  }

  async getTodayDoctors() {
    const startTime = moment.utc();
    const endTime = moment.utc().add(1, 'day');

    return await this.getAppointmentDoctors(startTime, endTime);
  }

  async getAppointmentDoctors(startTime?: moment.Moment, endTime?: moment.Moment) {
    const startTimeStr = startTime ? (startTime.format("YYYY-MM-DD") + "Z") : null;
    const endTimeStr = endTime ? (endTime.format("YYYY-MM-DD") + "Z") : null;

    const { data } = await GraphqlService.queryGql<DoctorSchema[]>({
      method: "appointmentDoctors",
      fields: ["id", "displayName", "doctorImage"],
      variables: [
        { field: "startTime", value: startTimeStr, valueType: "DateTimeType" },
        { field: "endTime", value: endTimeStr, valueType: "DateTimeType" },
      ],
      order: [{ field: "displayName", value: "ASC" }]
    });
    return data ?? [];
  }

  async getNewAppointments(limit: number, page: number, afterDay: number, userIds: number[]) {
    const startTime = moment.utc().add(afterDay, "days").format("YYYY-MM-DD") + "Z";
    const endTime = moment.utc().add(afterDay + 1, 'days').format("YYYY-MM-DD") + "Z";

    const params: TabulatorParams = {
      size: limit,
      page: page,
      filter: [
        { field: "startTime", type: ">=", value: startTime, valueType: "DateTime!" },
        { field: "startTime", type: "<", value: endTime, valueType: "DateTime!" },
        { field: "status", type: "in", value: [AppointmentStatus.None, AppointmentStatus.New] }
      ],
      sort: [{ field: "startTime", dir: "asc" }]
    }

    if (userIds.length) {
      params.filter.unshift({ field: "userId", type: "in", value: userIds, valueType: "[Int!]" })
    }

    return await GraphqlService.getItems<AppointmentSchema>("appointments", fieldsAppointment, params);
  }

  async getNumberOfAppointmentsToday(userIds: number[]) {
    const startTime = moment().utc().format("YYYY-MM-DD") + "T00:00:00Z";
    const endTime = moment().utc().add(1, 'day').format("YYYY-MM-DD") + "T00:00:00Z";
    // https://chillicream.com/docs/hotchocolate/v13/fetching-data/filtering
    const userIdStr = userIds.length ? `userId: {in:${JSON.stringify(userIds)}}` : '';

    const { client } = useApolloClient();
    const { data } = await client.query<{
      new: { totalCount: number };
      canceled: { totalCount: number };
      approved: { totalCount: number };
      totalCount: number;
    }>({
      query: gql`
        query appointments($startTime: DateTime!, $endTime: DateTime!, $statusNew: [AppointmentStatus!], $statusCanceled: [AppointmentStatus!], $statusApproved: [AppointmentStatus!]) {
          new:appointments(where: {
            ${userIdStr}
            startTime: {gte:$startTime, lt:$endTime}
            status: {in:$statusNew}
          }) {
            totalCount
          }
          canceled:appointments(where: {
            ${userIdStr}
            startTime: {gte:$startTime, lt:$endTime}
            status: {in:$statusCanceled}
          }) {
            totalCount
          }
          approved:appointments(where: {
            ${userIdStr}
            startTime: {gte:$startTime, lt:$endTime}
            status: {in:$statusApproved}
          }) {
            totalCount
          }
        }
      `,
      variables: {
        startTime: startTime,
        endTime: endTime,
        statusNew: [AppointmentStatus.None, AppointmentStatus.New, AppointmentStatus.ReminderSent],
        statusCanceled: [AppointmentStatus.Canceled, AppointmentStatus.DidNotCome],
        statusApproved: [AppointmentStatus.Approved, AppointmentStatus.TookPlace],
      }
    });
    return {
      new: data.new.totalCount,
      canceled: data.canceled.totalCount,
      approved: data.approved.totalCount,
      totalCount: data.new.totalCount + data.canceled.totalCount + data.approved.totalCount
    };
  }

  async getDayCountOfAppointments(daysCount: number, userIds: number[]) {
    const fromDate = moment.utc().format("YYYY-MM-DD") + "T00:00:00Z";
    const toDate = moment.utc().add(daysCount, "days").format("YYYY-MM-DD") + "T00:00:00Z";
    const userIdStr = userIds.length ? `userId: {in:${JSON.stringify(userIds)}}` : '';

    let days: Date[] = [];

    // https://stackoverflow.com/a/69371205
    function generateQuery() {
      let query = "";
      // https://stackoverflow.com/a/10040679
      let now = new Date(toDate);
      let i = 0;
      for (let d = new Date(fromDate); d <= now; d.setDate(d.getDate() + 1)) {
        days[i] = new Date(d);
        let day1 = new Date(d);
        day1.setDate(day1.getDate() + 1);
        const startTimeStr = `startTime: {gte:"${days[i].toISOString().substring(0, 10)}Z", lt:"${day1.toISOString().substring(0, 10)}Z"}`;
        query += `
          new_${i}:appointments(where: {
            ${userIdStr}
            ${startTimeStr}
            status: {in:$statusNew}
          }) {
            totalCount
          }
          canceled_${i}:appointments(where: {
            ${userIdStr}
            ${startTimeStr}
            status: {in:$statusCanceled}
          }) {
            totalCount
          }
          approved_${i}:appointments(where: {
            ${userIdStr}
            ${startTimeStr}
            status: {in:$statusApproved}
          }) {
            totalCount
          }
        `;
        //console.log(days[i].toISOString().substring(0, 10) + ' - ' + day1.toISOString().substring(0, 10));
        i++;
      }
      return query;
    }

    const { client } = useApolloClient();
    const { data } = await client.query<any>({
      query: gql`
        query appointments($statusNew: [AppointmentStatus!], $statusCanceled: [AppointmentStatus!], $statusApproved: [AppointmentStatus!]) {
          ${generateQuery()}
        }
      `,
      variables: {
        statusNew: [AppointmentStatus.None, AppointmentStatus.New, AppointmentStatus.ReminderSent],
        statusCanceled: [AppointmentStatus.Canceled, AppointmentStatus.DidNotCome],
        statusApproved: [AppointmentStatus.Approved, AppointmentStatus.TookPlace],
      }
    });

    let array = [];
    for (let i = 0; i < days.length; i++) {
      array[i] = {
        day: days[i],
        new: data[`new_${i}`].totalCount,
        canceled: data[`canceled_${i}`].totalCount,
        approved: data[`approved_${i}`].totalCount,
      };
    }

    return array;
  }

  async getMonthCountOfAppointments(fromDate: DateTimeString, toDate: DateTimeString) {
    let months = [] as Date[];

    function generateQuery() {
      let query = "";
      let now = new Date(toDate);
      let i = 0;
      for (let d = new Date(fromDate); d <= now; d.setMonth(d.getMonth() + 1)) {
        months[i] = new Date(d);
        let month1 = new Date(d);
        month1.setMonth(month1.getMonth() + 1);
        query += `
          _${i}:appointments(where: {
            startTime: {gte:"${months[i].toISOString().substring(0, 7)}-01Z", lt:"${month1.toISOString().substring(0, 7)}-01Z"}
          }) {
            totalCount
          }
        `;
        i++;
      }
      return query;
    }

    const { client } = useApolloClient();
    const { data } = await client.query<any>({
      query: gql`
        query appointments {
          ${generateQuery()}
        }
      `,
      variables: {}
    });

    let array = [];
    for (let i = 0; i < months.length; i++) {
      array[i] = {
        month: months[i],
        count: data[`_${i}`].totalCount as number
      };
    }

    return array;
  }

  async saveAppointment(input: SaveAppointmentInput) {
    return await GraphqlService.setItem<AppointmentSchema>("saveAppointment", "appointment", fieldsAppointment, input);
  }

  async approveAppointment(id: number) {
    return this.changeAppointmentStatus(id, AppointmentStatus.Approved);
  }

  async deleteAppointment(id: number, userId: number, reason: string) {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "deleteAppointment",
      variables: [
        { field: "id", value: id, valueType: "ID!" },
        { field: "userId", value: userId, valueType: "ID!" },
        { field: "reason", value: reason, valueType: "String!" }
      ]
    });
    return data ?? false;
  }

  async copyAppointment(id: number, startTime: Date, deleteSource: boolean, doctorId: number) {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "copyAppointment",
      variables: [
        { field: "id", value: id, valueType: "ID!" },
        { field: "startTime", value: startTime, valueType: "DateTimeType!" },
        { field: "deleteSource", value: deleteSource, valueType: "Boolean!" },
        { field: "doctorId", value: doctorId, valueType: "ID!" }
      ]
    });
    return data;
  }

  async copyAppointmentFromTreatment(treatmentId: number, startTime: Date, doctorId: number) {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "copyAppointmentFromTreatment",
      variables: [
        { field: "treatmentId", value: treatmentId, valueType: "ID!" },
        { field: "startTime", value: startTime, valueType: "DateTimeType!" },
        { field: "doctorId", value: doctorId, valueType: "ID!" }
      ]
    });
    return data;
  }

  async resizeOrDropAppointment(id: number, startTime: DateTimeString, duration: TimeSpanString, doctorId?: number) {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "resizeOrDropAppointment",
      variables: [
        { field: "id", value: id, valueType: "ID!" },
        { field: "startTime", value: startTime, valueType: "DateTimeType!" },
        { field: "duration", value: duration, valueType: "TimeSpan!" },
        { field: "doctorId", value: doctorId, valueType: "ID" },
      ]
    });
    return data;
  }

  async changeAppointment(id: number, startTime: DateTimeString, doctorId: number) {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "changeAppointment",
      variables: [
        { field: "id", value: id, valueType: "ID!" },
        { field: "startTime", value: startTime, valueType: "DateTimeType!" },
        { field: "doctorId", value: doctorId, valueType: "ID!" },
      ]
    });
    return data;
  }

  async changeAppointmentStatus(id: number, status: AppointmentStatus) {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "changeAppointmentStatus",
      variables: [
        { field: "id", value: id, valueType: "ID!" },
        { field: "status", value: status, valueType: "AppointmentStatus!" },
      ]
    });
    return data;
  }

  async cancelAppointment(id: number, userId: number, reason: string) {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "cancelAppointment",
      variables: [
        { field: "id", value: id, valueType: "ID!" },
        { field: "userId", value: userId, valueType: "ID!" },
        { field: "reason", value: reason, valueType: "String!" }
      ]
    });
    return data;
  }

  async sendAppointmentLink(doctorId: number, patientId: number, serviceId: number | null, messageProvider: MessageProvider, destination: string, subject: string, text: string): Promise<Result> {
    const { data } = await GraphqlService.mutateGql<Result>({
      method: "sendAppointmentLink",
      fields: `success exception`,
      variables: [
        { field: "doctorId", value: doctorId, valueType: "Int!" },
        { field: "patientId", value: patientId, valueType: "Int!" },
        { field: "serviceId", value: serviceId, valueType: "Int" },
        { field: "provider", value: messageProvider, valueType: "MessageProvider!" },
        { field: "destination", value: destination, valueType: "String!" },
        { field: "subject", value: subject, valueType: "String!" },
        { field: "text", value: text, valueType: "String!" },
      ]
    });
    return data ?? { success: false, exception: "Empty result" };
  }

  async validatePatientForAppointment(phone1: string, phone2: string | null, passportNumber: string | null) {
    const { data } = await GraphqlService.queryGql<boolean>({
      method: "validatePatientForAppointment",
      variables: [
        { field: "phone1", value: phone1, valueType: "String!" },
        { field: "phone2", value: phone2, valueType: "String" },
        { field: "passportNumber", value: passportNumber, valueType: "String" }
      ]
    });
    return data ?? false;
  }
}
