import moment from "@/plugins/VueMomentPlugin";
import GraphqlService from "../GraphqlService";
import type { AttendanceReportModel, AttendanceReportTabulatorModel } from "@/models/api/queries/AttendanceModels";
import type { TabulatorParams } from "@/models/interfaces/TabulatorParams";

const fields = `
  reportRecords {
    userId
    fullName
    userImage
    attendanceDurationsList
    userAttendanceDurationSummary
  }
  attendanceDurationSummaryByDates
`;

export default class AttendanceReportService {
  async getAttendanceReport(params: TabulatorParams, fromDate: Date, toDate: Date, doctorIds: number[]) {
    const { data } = await GraphqlService.queryGql<AttendanceReportModel>({
      method: "attendanceReport",
      fields: fields,
      variables: [
        { field: "fromDate", value: fromDate, valueType: "DateTimeType!" },
        { field: "toDate", value: toDate, valueType: "DateTimeType!" },
        { field: "doctorIds", value: doctorIds, valueType: "[Int!]" },
      ],
    });

    const fromDateMoment = moment(fromDate);
    const toDateTimeMoment = moment(toDate);

    const result: AttendanceReportTabulatorModel[] = [];

    data?.reportRecords.forEach((reportRecord) => {
      let reportTabulatorModel: AttendanceReportTabulatorModel = {
        id: reportRecord.userId,
        userImage: reportRecord.userImage,
        fullName: reportRecord.fullName,
        userAttendanceDurationSummary: reportRecord.userAttendanceDurationSummary,
      }
      let columnIndex = 1;
      for (let date = fromDateMoment.clone(); date.isBefore(toDateTimeMoment); date.add(1, "days")) {
        (reportTabulatorModel as any)[`date_column_${columnIndex}`] = reportRecord.attendanceDurationsList[columnIndex - 1];
        columnIndex++
      }
      result.push(reportTabulatorModel);
    })

    return result;
  }
}