export default {
  newAppointment: "New appointment",
  updateAppointment: "Update appointment",
  date: "Date",
  color: "Color",
  startTime: "Start time",
  duration: "Duration",
  addToWaitingList: "Add to waiting list",
  doctor: "Doctor",
  services: "Services",
  treatmentCode: "Treatment code",
  treatmentType: "Treatment type",
  treatments: "Add from treatment plans",
  notes: "Notes",
  newPatient: "New patient",
  existingPatient: "Existing patient",
  firstName: "First name",
  lastName: "Last Name",
  passportNumber: "Passport number",
  city: "City",
  phone2: "Additional phone",
  patient: "Patient",
  cancel: "Cancel",
  save: "Save",
  dateUnavailable: "The doctor is not working at this time",
  dateUnavailableConfirm: "The selected timeframe falls outside of regular working hours. Would you like to proceed anyway?",
  anotherAppointment: {
    text: "There is another appointment at that time. Should I continue?",
    yes: "Continue",
    no: "Cancel"
  },
  popupDelete: {
    title: "Delete appointment",
    text: "Delete appointment?",
    accept: "Accept",
    cancel: "Cancel",
    moveToWaitlist: "To waitlist",
    cancellationReason: "Cancellation reason",
    validationReasonRequired: "Reason for cancellation is mandatory"
  },
  popupLink: {
    header: "Send an appointment Link",
    linkMessage: "A link to an appointment will be automatically attached at the end of the message.",
    successMessage: "Message were sent successfully",
  }
}
