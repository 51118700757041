import { useServices } from "@/services";
import GraphqlService from "../GraphqlService";
import type { PatientSchema } from "@/models/schemas";

export default class ScannersService {
  async startApplicationByLocalPath(localPath: string, patientId: number, isFileNumber: boolean, deleteFirst0InPassportNumber: boolean): Promise<boolean> {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "startApplicationByLocalPath",
      variables: [
        { field: "localPath", value: localPath, valueType: "String!" },
        { field: "patientId", value: patientId, valueType: "Int!" },
        { field: "isFileNumber", value: isFileNumber, valueType: "Boolean!" },
        { field: "deleteFirst0InPassportNumber", value: deleteFirst0InPassportNumber, valueType: "Boolean!" },
      ],
    });
    return data ?? false;
  }

  getProtocolLink(link: Record<string, string>, patient: PatientSchema, doctorName?: string) {
    const passportNumber = link["deleteFirst0InPassportNumber"] ? patient.passportNumber?.replace(/^0+/, "") : patient.passportNumber;
    //const token = useServices().apiAuth.token?.access;
    return [
      link["remoteUrl"],
      //patient.id,
      link["sendPassportNumberOrFileNumber"] == "FileNumber" ? patient.fileNumber : passportNumber,
      patient.firstName,
      patient.lastName,
      patient.birthDate,
      doctorName || patient.defaultDoctorName,
      //token,
    ].join('/')
  }
}
