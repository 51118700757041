export default {
  head: {
    title: "Databases",
    meta: [
      { name: "description", content: "Database Management" }
    ]
  },
  login: {
    enterPassword: "Enter password"
  },
  table: {
    columns: {
      id: "ID",
      createdAt: "Created",
      uniqueName: "Name",
      license: "License",
    }
  },
  createDatabase: "Create Database",
  updateDatabase: "Update Database",
  updateDatabaseLicenseSettings: "Update database license settings",
  input: {
    name: "Database name",
    password: "Admin password",
    license: "License",
    licenseMatchesValidationMessage: "Please use only lowercase letters, numbers, and the symbols '-' and '_'.",
  },
  exists: "Database already exists.",
  saved: "Database saved!",
  restartAlert: "Please restart site service",
}
