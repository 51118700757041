export default {
  head: {
    title: "הכנסות",
    meta: [
      { name: "description", content: "הכנסות" }
    ]
  },
  descriptionLegend: "כותרת המסמך",
  documentDetailsLegend: "פרטי המסמך",
  documentDate: "מועד המסמך",
  dueDate: "לתשלום עד",
  patient: "מטופל",
  emailLegend: 'שליחת המסמך בדוא"ל',
  patientEmails: 'כאן נגדיר לאילו כתובות דוא"ל יישלח מסמך המקור',
  emailContent: 'טקסט בגוף הודעת הדוא"ל',
  paymentLegend: "קבלת תשלום אונליין",
  paymentAllow: "הוסיפו כפתור תשלום למייל שנשלח ללקוח.",
  paymentMaxNumber: " מספר תשלומים מירבי ",
  discount: "הנחה",
  vat: 'מע"מ',
  rounding: "עיגול סכום",
  total: "סה\"כ לתשלום",
  subtotal: 'סה"כ',
  notesLegend: "הערות נוספות בתחתית המסמך",
  documentFooter: "כאן אפשר להוסיף כל מידע חשוב על הטיפולים או החיוב",
  vatTypeLabel: "סוג המע״מ",
  languageLabel: "שפה",
  submit: "הפקת מסמך",
  preview: "תצוגה מקדימה",
  draft: "שמירת טיוטה",
  addReceiptItem: "הוסיפו פריט",
  addInvoiceItem: "הוספת שירות",
  addService: "הוספה מתבניות",
  services: "שירותים",
  addTreatment: "הוספה מטיפולים שהסתיימו",
  addFreeField: "שדה חופשי",
  invoiceTableHeader: "פריטי המסמך",
  receiptTableHeader: "פירוט תקבולים",
  receiptTableMessage: "איך שילמו לך? אם שילמו לך בכמה צורות תשלום, אפשר לבחור כמה סוגי תקבולים.",
  actions: "פעולות",
  totalReceipt: "סה״כ לתשלום",
  bankBranch: "סניף",
  bankAccount: "חשבון",
  chequeNumber: "מס׳",
  vatType: {
    NONE: "None",
    NORMAL: "רגיל",
    NO_VAT: "ללא מע״מ (אילת וחו״ל)"
  },
  language: {
    NONE: "None",
    ENGLISH: "אנגלית",
    HEBREW: "עברית",
  },
  discountType: {
    NONE: "None",
    PERCENT: "אחוז",
    AMOUNT: "סכום"
  },
  backPopup: {
    text: "להמשיך בלי לשמור? אפשר לשמור את המסמך כטיוטה ולחזור אליו אחר כך",
    confirm: "אפשר לעבור",
    cancel: "חזרה לעריכה"
  },
  validation: {
    form: "לתשומת ליבך, נראה שלא מילאת את כל הפרטים הנחוצים במסמך",
    service: "שירות חובה",
    draft: "לא כל השדות תקפים",
    patient: "יש לבחור מטופל",
    discountValue: "בדקו הנחה",
    quantity: "כמות חובה",
    itemPrice: "מחיר חובה",
    invoiceReceipt: "סכום שורות בחשבונית צריך להיות זהה לסכום תקבולים.",
    notSentToPatient: "הקובץ נשמר אך לא נשלח למטופל"
  },
  invoiceLineTable: {
    treatment: "טיפולים של לקוח",
    description: "פירוט שירותים",
    quantity: "כמות",
    itemPrice: "מחיר ליח'",
    vat: "מע\"מ",
    amount: "סה\"כ"
  },
  receiptLineTable: {
    // common
    date: "תאריך",
    amount: "סה\"כ",
    type: "סוג",
    details: "פרטים",
    // bankTransfer
    transferBankName: "בנק",
    transferBankBranch: "סניף",
    transferBankAccount: "חשבון",
    // cheque
    chequeBankName: "בנק",
    chequeBankBranch: "סניף",
    chequeBankAccount: "חשבון",
    chequeNumber: "מספר צ'ק",
    // creditCard
    number4Digits: "4 ספרות אחרונות",
    brand: "סוג כרטיס",
    dealType: "סוג עסקה",
    payNumber: "מספר תשלמים",
    // other
    selectBank: "בחירת בנק ...",
  },
  payment: {
    button: "חיוב אשראי",
    modal: {
      errorNegative: "סכום יתרה צריך להיות חיובי כדי לבצע סליקה.",
      title: "חיוב הלקוח באשראי (סליקה)",
      alert: [
        "חשוב לקרוא לפני החיוב!",
        "מיד אחרי סליקת האשראי, המסמך יופק אוטומטית וישלח ללקוח. לפני הפעולה הזו ודאו שהזנתם את כל פרטי השליחה במייל ותקבולים נוספים אם היו. לא תוכלו לצפות במסמך לפני הפקתו או לחזור ולתקן פרטים."
      ],
      method: "אופן חיוב",
      methods: {
        form: "טופס אשראי",
        terminal: "מסוף אשראי"
      },
      sum: "סכום",
      maxPayments: "מספר תשלומים מירבי",
      continue: "לחיוב הלקוח",
      back: "חזרה לעריכה",
      success: "התשלום בוצע בהצלחה",
      fail: "התשלום נכשל",
    }
  }
}
