import type { DateTimeString } from "@/models/interfaces";
import type { HistoryAction, EntityType } from "@/models/enums";

export interface HistorySchema {
  id: number;
  createdAt: DateTimeString;

  action: HistoryAction;
  userId: number | null;

  entity: EntityType;
  entityId: number;

  field: string | null;
  valueOld: string | null;
  valueNew: string | null;

  userName: string;
}

export const historySchemaAllFields = [
  "id",
  "createdAt",

  "action",
  "userId",

  "entity",
  "entityId",

  "field",
  "valueOld",
  "valueNew",

  "userName",
];
