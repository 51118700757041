export default {
  head: {
    title: "יומן",
    meta: [
      { name: "description", content: "יומן" }
    ]
  },
  day: {
    title: "יום"
  },
  week: {
    title: "שבוע"
  },
  list: {
    title: "תצוגת רשימה"
  },
  listShort: {
    title: "רשימה"
  },
  popups: {
    printCalendar: {
      title: "הדפסת יומן",
      dateFrom: "מתאריך",
      dateTo: "עד תאריך",
      submit: "הדפסה, הורדת PDF"
    },
    cancelAppointment: {
      title: "סיבת הביטול",
      reason: "סיבת הביטול",
      submit: "אישור",
      validationReasonRequired: "סיבת הביטול הינה חובה"
    }
  },
  contextMenu: {
    addAppointment: "תור חדש של {0}",
    addFromWaitingList: "הוסף מרשימת המתנה",
    addGeneralAppointment: "פגישה כללית",
    patientCard: "כרטיס מטופל",
    patientTreatmentPlan: "תוכנית טיפול",
    sendQuestionary: "שליחת שאלון/טופס",
    tasks: "משימות",
    laboratoryWorks: "עבודות מעבדה",
    appointments: "תורים",
    family: "משפחה",
    payments: "תשלומים",
    addPayment: "הוספת תשלום",
    sendReminder: "שליחת תזכורת",
    appointmentLink: "שליחת קישור לזימון תור",
    sendMessage: "שליחת הודעה",
    callToPatient: "להתחיל שיחה",
    changeGeneralAppointment: "שיוך פגישה למטופל",
    cameIn: "אימות הגעת מטופל",
    didNotCame: "עדכון על אי הגעת מטופל",
    reminderApproved: "עדכון על אישור הגעת המטופל",
    cancelled: "עדכון על ביטול תור",
    clear: "איפוס סטטוס",
    addTreatment: "הוספת טיפול",
    copyAppointment: "העתק",
    pasteAppointment: "הדבק",
    changeColor: "שנה צבע",
    noDoctors: "Doctor not selected",
    cutAppointment: "גזור"
  },
  sendReminders: "שליחת תזכורת",
  waitingList: "רשימת המתנה",
  settings: "הגדרות יומן",
  addAppointment: "תור חדש",
  chooseDoctor: "בחר רופא",
  generalAppointment: "פגישה כללית",
  today: "היום",
  service: "שירות",
  notes: "הערות",
  from: "משעה",
  duration: "משך",
  status: "סטטוס",
  reminders: "תזכורות"
}
